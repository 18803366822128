import styled, { css } from 'styled-components'

const Container = styled.div`
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 30px;

  ${props =>
    props.noPadding &&
    css`
      padding: 0;
    `}

  ${props =>
    props.medium &&
    css`
      max-width: 800px;
    `}

    ${props =>
      props.small &&
      css`
        max-width: 500px;
      `}
`

export default Container

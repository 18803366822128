import Button from './Button'
import Container from './Container'
import Question from './Question'
import React from 'react'
import questions from '../questionsAnswers'
import styled from 'styled-components'

const Form = styled.form`
  margin: 70px 0 100px;
`

const Ul = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 70px;
`

const Flex = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
`

const Clear = styled.p`
  color: red;
  margin: 0;
  padding: 0;
  cursor: pointer;
  transition: 0.5s;

  &:hover {
    opacity: 0.5;
  }
`

const NameContainer = styled.div`
  margin: 0 0 100px;
`

const NameLabel = styled.label`
  margin-right: 15px;
`

const NameInput = styled.input`
  padding: 15px 20px;
  border: solid 1px #3a3a3a;
  outline: none;
  appearance: none;
`

function clearLocalStorageAnswers() {
  let confirmation = window.confirm('Are you sure you wish to clear all answers and start again?')

  if (!confirmation) return

  window.scrollTo(0, 0)
  window.localStorage.clear()
  window.location.reload()
}

function capitalize(t) {
  return t.replace(/(?:^|\s)\S/g, a => a.toUpperCase())
}

export default function Questions() {
  return (
    <Container>
      <Form action="/results" method="GET">
        <NameContainer>
          <NameLabel htmlFor="name">Please enter your full name:</NameLabel>
          <NameInput
            onChange={e => {
              window.localStorage.setItem('name', capitalize(e.target.value.trim()))
            }}
            defaultValue={window.localStorage.getItem('name') || ''}
            type="text"
            required
            placeholder="e.g. John Doe"
          />
        </NameContainer>

        <Ul>
          {questions.map((question, key) => (
            <Question key={key} questionKey={key} {...question}></Question>
          ))}
        </Ul>

        <Flex>
          <Button type="submit">Submit Answers</Button>

          <Clear onClick={() => clearLocalStorageAnswers()}>Clear all answers</Clear>
        </Flex>
      </Form>
    </Container>
  )
}

import React, { Fragment } from 'react'
import { Route, Router, Switch } from 'react-router-dom'

import GlobalStyles from './styles/globalStyles'
import Header from './components/Header'
import Questions from './components/Questions'
import Results from './components/Results'
import { createBrowserHistory } from 'history'

const history = createBrowserHistory()

function App() {
  return (
    <Router history={history}>
      <Fragment>
        <GlobalStyles />

        <Header />

        <Switch>
          <Route component={Questions} exact path="/" />
          <Route component={Results} path="/results" />

          {/* Catch All */}
          <Route component={Questions} />
        </Switch>
      </Fragment>
    </Router>
  )
}

export default App

import { createGlobalStyle } from 'styled-components'
import v from './variables'

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: ${v.fontSize.five};
    line-height: ${v.lineHeight.large};
    font-family: montserrat, sans-serif;
    font-weight: ${v.fontWeight.normal};
    color: ${v.color.darkGrey};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: ${v.lineHeight.small};
    font-weight: ${v.fontWeight.bold};
  }

  h1 { font-size: ${v.fontSize.one}; }
  h2 { font-size: ${v.fontSize.two}; }
  h3 { font-size: ${v.fontSize.three}; }
  h4 { font-size: ${v.fontSize.four}; }
  h5 { font-size: ${v.fontSize.five}; }
  h6 { font-size: ${v.fontSize.six}; }

  strong {
    font-weight: ${v.fontWeight.bold};
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`

export default GlobalStyles

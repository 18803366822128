export default {
  color: {
    green: '#45aa8b',
    purple: '#61116a',
    pink: '#ba0a7e',
    blue: '#1C89E3',
    darkGrey: '#606060',
    lightGrey: '#f5f5f5'
  },
  lineHeight: {
    small: 1.25,
    medium: 1.5,
    large: 1.75
  },
  fontSize: {
    one: '2.5em',
    two: '2em',
    three: '1.5em',
    four: '1.25em',
    fourPointFive: '1.125em',
    five: '1em',
    fivePointFive: '.9em',
    six: '0.8em'
  },
  fontWeight: {
    normal: '400',
    bold: '600'
  }
}

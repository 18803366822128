import React from 'react'
import styled from 'styled-components'
import v from '../styles/variables'

const QuestionEl = styled.li`
  &:not(:last-of-type) {
    padding-bottom: 50px;
    margin-bottom: 50px;
    border-bottom: solid 1px ${v.color.lightGrey};
  }
`

const QuestionText = styled.p`
  font-size: ${v.fontSize.four};
  font-weight: ${v.fontWeight.bold};
  margin: 0 0 30px;
  width: 800px;
  max-width: 100%;

  color: ${props => (props.incorrect ? 'red' : 'inherit')};
`

const Option = styled.p`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  margin: 0;

  &:not(:last-of-type) {
    margin-bottom: 15px;
  }
`

const Input = styled.input`
  cursor: pointer;
  margin-right: 15px;
  transition: 0.5s;

  &:checked + label {
    opacity: 0.5;
  }
`

const Label = styled.label`
  transition: 0.5s;
  cursor: pointer;
  width: 800px;
  max-width: calc(100% - 50px);
`

function saveAnswerToLocalStorage(questionNumber, newValue) {
  window.localStorage.setItem(`question-${questionNumber}-answer`, newValue)
}

function getLocalStorageAnswer(questionNumber) {
  let answer = window.localStorage.getItem(`question-${questionNumber}-answer`)

  return answer ? Number(answer) : false
}

export default function Question(props) {
  return (
    <QuestionEl>
      <QuestionText
        incorrect={
          window.location.search.includes('failed=true') && getLocalStorageAnswer(props.questionKey) !== props.correct
        }>
        {props.questionKey + 1} - {props.question}
      </QuestionText>

      {props.answers.map((answer, key) => {
        let uniqueQuestionKey = props.questionKey + '-' + key

        return (
          <Option key={key}>
            <Input
              onChange={e => saveAnswerToLocalStorage(props.questionKey, e.target.value)}
              defaultChecked={getLocalStorageAnswer(props.questionKey) === key}
              required
              type="radio"
              name={props.questionKey}
              id={uniqueQuestionKey}
              value={key}
            />
            <Label htmlFor={uniqueQuestionKey}>{answer}</Label>
          </Option>
        )
      })}
    </QuestionEl>
  )
}

export default [
  {
    question: 'What grade of operator is permitted to run tests with SureScreen Urinalysis Reagent Strips?',
    correct: 1,
    answers: [
      'Any member of staff can analyse patient samples using SureScreen Urinalysis Reagent Strips',
      'Only staff that have been trained and are competent are permitted to analyse patient samples using SureScreen Urinalysis Reagent Strips',
      'Patients can test themselves using SureScreen Urinalysis Reagent Strips after reading the instructions for use'
    ]
  },
  {
    question:
      'An integral part of training on the use of SureScreen Urinalysis Reagent Strips includes the requirement to have read which two documents?',
    correct: 2,
    answers: [
      "SureScreen Diagnostics' terms & conditions and standard operating procedure (SOP)",
      "Employee consent declaration & SureScreen Diagnostics' terms & conditions",
      'Standard operating procedure (SOP) & associated kit insert'
    ]
  },
  {
    question: 'Where are the SOP and kit insert located?',
    correct: 1,
    answers: [
      'Kit inserts and SOP information can both be found on the resources page of the SureScreen Diagnostics website',
      'Each test centre will have access to an electronic copy of the SOP. Kit inserts can be found within this SOP or in each box of reagent strips',
      'Only SureScreen Diagnostics have access to these documents due to their sensitive nature'
    ]
  },
  {
    question: 'What tests are run on SureScreen Urinalysis Reagent Strips?',
    correct: 0,
    answers: [
      'Leukocytes, Nitrite, Protein, pH, Blood, Ketones & Glucose',
      'Leukocytes, Nitrite, HbA1c, pH, Ketones & Glucose',
      'Cholesterol, Leukocytes, Nitrite, Protein, pH, Blood & Ketones'
    ]
  },
  {
    question: 'What temperature should strips be stored at?',
    correct: 0,
    answers: ['Room Temperature (2-30°C)', 'Refrigerated (<2°C)']
  },
  {
    question: 'Once opened, how long are the strips stable and able to be used for?',
    correct: 2,
    answers: [
      'Stable for 3 days after opening or until expiry on container - whichever comes first',
      'Stable for 3 weeks after opening or until expiry on container - whichever comes first',
      'Stable for 3 months after opening or until expiry on container - whichever comes first'
    ]
  },
  {
    question: 'What is the most important aspect to remember regarding strip stability?',
    correct: 1,
    answers: [
      'Ensuring new strips are thoroughly cleansed before use',
      'Ensuring the lid is securely replaced immediately after removing each test strip',
      'Ensuring sanitised gloves are always worn when handling strips'
    ]
  },
  {
    question: 'Why is it important to securely replace the lid after removing a test strip?',
    correct: 0,
    answers: [
      'Atmospheric nitrogen oxides will react with the strip if the cap is not replaced promptly',
      'So the lid is not lost and the test rendered useless',
      'The strips may be affected by room temperature'
    ]
  },
  {
    question: 'What may indicate reagent strip deterioration?',
    correct: 2,
    answers: [
      'Minute shrinking or deformation of the test pads',
      'A faint smell of alcohol from the test pads',
      'Discolouration or darkening of test pads'
    ]
  },
  {
    question: 'What should be written on the reagent strip container after opening for the first time?',
    correct: 1,
    answers: ['The current date', 'The date of expiry (current date + 3 months)', 'Name of the company testing']
  },
  {
    question: 'What is the specimen type?',
    correct: 0,
    answers: [
      '10ml - 15ml of freshly voided urine (preferably fresh early morning)',
      '5ml - 7.5ml of freshly voided urine thoroughly mixed with 5ml - 7.5ml (1:1) of sterilised water',
      '5ml of freshly voided urine thoroughly mixed with 3 drops (0.375ml) of reagent catalyst'
    ]
  },
  {
    question: 'What details should be included on each patient sample label?',
    correct: 2,
    answers: [
      'Date and time of collection, the temperature of the testing room, forename, surname, full DOB, H&C number',
      'The temperature of the testing room, forename, surname, full DOB, date and time of collection',
      'Forename, surname, full D.O.B, H&C number, date and time of collection'
    ]
  },
  {
    question: 'When should the specimen be analysed?',
    correct: 1,
    answers: [
      'Samples should be tested 2 minutes after collection for optimal results',
      'Samples should be tested as soon as possible after collection for optimal results',
      'Samples should be tested 30 minutes after collection for optimal results'
    ]
  },
  {
    question: 'At what temperature should the strips and sample be tested?',
    correct: 2,
    answers: ['Any temperature below 0°C', 'Between 5°C & 10°C', 'Room temperature']
  },
  {
    question: 'How long should the sample be dipped into urine?',
    correct: 0,
    answers: ['No more than 2 seconds', 'At least 5 seconds', 'Between 70 and 90 seconds']
  },
  {
    question: 'At what time should all the analytes except leucocytes be read at?',
    correct: 1,
    answers: ['30 seconds', '60 seconds', '90 seconds']
  },
  {
    question: 'At what time should leucocytes be read at?',
    correct: 2,
    answers: ['30 seconds', '60 seconds', '90 seconds']
  },
  {
    question: 'When reading the test, which way should the test strip be held?',
    correct: 1,
    answers: [
      'With the strip held between your thumb and forefinger and the reagent pads facing downwards',
      'With the strip held between your thumb and forefinger and the reagent pads facing upwards',
      'With the strip held between your thumb and forefinger and the reagent pads level'
    ]
  },
  {
    question: 'How are results reported?',
    correct: 2,
    answers: [
      'Using the online recording system',
      'By sending an email to results@surescreen.com',
      'Using the recording template provided'
    ]
  },
  {
    question: 'How should the used strip and sample be disposed of?',
    correct: 0,
    answers: ['As clinical waste', 'In a regular refuse bin', 'Down the toilet']
  },
  {
    question: 'To whom should the results be reported?',
    correct: 1,
    answers: ['To SureScreen Diagnostics (results@surescreen.com)', 'As per treatment room practice', 'To the donor']
  },
  {
    question: 'Where may the limitations of each test be found?',
    correct: 2,
    answers: ['Online', 'On the exterior packaging of the test', 'In SOP or kit insert']
  }
]

import Blip from './Blip'
import Button from './Button'
import Container from './Container'
import { Link } from 'react-router-dom'
import React from 'react'
import downloadPDF from '../pdf'
import questions from '../questionsAnswers'
import styled from 'styled-components'
import v from '../styles/variables'

/**
 * Check if the user has reached the required amount of correct answers to pass
 *
 * @return Object
 */
function isPass() {
  let givenAnswers = {}
  let correctAnswers = 0
  let numberOfQuestions = questions.length

  // The pass mark is 90%, this figures out how many
  // correct answers are required to reach 90%
  let correctAnswersToPass = Math.floor(numberOfQuestions * 0.9)

  // Assign the GET params to the givenAnswers object
  window.location.search
    .slice(1)
    .split('&')
    .forEach(function(pair) {
      pair = pair.split('=')
      givenAnswers[pair[0]] = decodeURIComponent(pair[1] || '')
    })

  // Loop through each question
  questions.forEach((question, key) => {
    // Increment correctAnswers if the given answer matches that defined in questionsAnswers.js
    question.correct === Number(givenAnswers[key]) && correctAnswers++
  })

  // Return test info
  return {
    pass: correctAnswers >= correctAnswersToPass,
    correctAnswers,
    correctAnswersToPass,
    numberOfQuestions
  }
}

const Flex = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  margin: 70px 0 100px;
`

const PassFail = styled.h1`
  position: relative;
  margin: 0 0 60px;
`

const CorrectAnswers = styled.h2`
  text-align: center;
  margin: 0;
  color: black;
  font-size: ${v.fontSize.one};

  span {
    color: ${props => (props.isPass ? 'green' : 'red')};
  }
`

const StyledButton = styled(Button)`
  margin-top: 60px;
`

export default function Results() {
  return (
    <Container>
      <Flex>
        <PassFail>
          <Blip pink />

          {isPass().pass ? 'Pass' : 'Fail'}
        </PassFail>

        <CorrectAnswers isPass={isPass().pass}>
          <span>{isPass().correctAnswers} /</span> {isPass().numberOfQuestions}
        </CorrectAnswers>

        {isPass().pass ? (
          <StyledButton onClick={() => downloadPDF(window.localStorage.getItem('name') || '')}>
            Download Certificate
          </StyledButton>
        ) : (
          <Link to="/?failed=true">
            <StyledButton>Try Again</StyledButton>
          </Link>
        )}
      </Flex>
    </Container>
  )
}

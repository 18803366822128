import styled, { css } from 'styled-components'

import v from '../styles/variables'

const Blip = styled.span`
  display: block;
  height: 0.5em;
  width: 0.5em;
  border-radius: 0 0.125em 0 0.125em;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);
  background: ${v.color.green};
  position: absolute;
  top: 0;
  right: calc(100% + 0.25em);
  transform: translateY(-12.5%);

  @media screen and (min-width: 1150px) {
    height: 0.75em;
    width: 0.75em;
    border-radius: 0 0.25em 0 0.25em;
  }

  ${props =>
    props.purple &&
    css`
      background: ${v.color.purple};
    `};

  ${props =>
    props.pink &&
    css`
      background: ${v.color.pink};
    `};

  ${props =>
    props.blue &&
    css`
      background: ${v.color.blue};
    `};
`

export default Blip

import styled, { css } from 'styled-components'

import React from 'react'
import v from '../styles/variables'

const ButtonStyle = styled.button`
  border-radius: 0 15px 0 15px;
  background: ${v.color.purple};
  text-align: center;
  border: none;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.25);
  outline: none;
  appearance: none;
  color: white;
  font-weight: ${v.fontWeight.bold};
  line-height: ${v.lineHeight.medium};
  cursor: pointer;
  transition: 0.5s;
  font-size: 1em;
  position: relative;
  overflow: hidden;
  font-family: inherit;

  i {
    margin-left: 7.5px;
  }

  span {
    display: block;
    height: 100%;
    padding: 15px;
    transition: 0.5s;

    &:last-of-type {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
    }
  }

  &:hover {
    border-radius: 15px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);
    transform: scale(1.025);

    span:first-of-type {
      transform: translateY(-100%);
    }

    span:last-of-type {
      top: 0;
    }
  }

  &:focus {
    outline: none;
    transform: scale(0.975);
  }

  ${props =>
    props.green &&
    css`
      background: ${v.color.green};
    `};

  ${props =>
    props.pink &&
    css`
      background: ${v.color.pink};
    `};

  ${props =>
    props.blue &&
    css`
      background: ${v.color.blue};
    `};
`

export default function(props) {
  return (
    <ButtonStyle {...props}>
      <span>{props.children}</span>
      <span>{props.children}</span>
    </ButtonStyle>
  )
}

import Container from './Container'
import LogoSrc from '../img/svg/surescreen-diagnostics-logo.svg'
import React from 'react'
import styled from 'styled-components'
import v from '../styles/variables'

const HeaderEl = styled.header`
  padding: 15px 0;
`

const Flex = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
`

const Logo = styled.img`
  line-height: 0;
  width: 270px;
  max-width: 100%;
  padding: 15px 0;
`

const Title = styled.h1`
  font-size: ${v.fontSize.four};
  padding: 15px 0;
  color: black;
`

export default function Header() {
  return (
    <HeaderEl>
      <Container>
        <Flex>
          <Logo src={LogoSrc} alt="SureScreen Diagnostics Logo" />
          <Title>Urinalysis Competency Test</Title>
        </Flex>
      </Container>
    </HeaderEl>
  )
}
